.tabItemSelected {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(26, 35, 126);
  }

}
.tabItemSelected:hover {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(248, 248, 248);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(26, 35, 126);
  }

}
.tabItemSelected:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(237, 237, 240);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(26, 35, 126);
  }

}
.tabItemSelected:focused {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(26, 35, 126);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(26, 35, 126);
  }

}
.tabItemSelected:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(210, 211, 223);
  }

}
.tabItemUnselected {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(255, 255, 255);
  }

}
.tabItemUnselected:hover {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(248, 248, 248);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(248, 248, 248);
  }

}
.tabItemUnselected:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(237, 237, 240);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(255, 255, 255);
  }

}
.tabItemUnselected:focused {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(26, 35, 126);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(255, 255, 255);
  }

}
.tabItemUnselected:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: center;
    flex: 1 1 auto;
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 1rem 0rem 1rem;
    align-self: center;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .selector {
    align-self: stretch;
    flex: 0 0 0.25rem;
    background-color: rgb(255, 255, 255);
  }

}