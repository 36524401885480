.menuItemList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .mainMenuItem {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    flex: 0 0 3rem;
  }

}

@media (min-width: 1024px) {
  .menuItemList {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .mainMenuItem {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 0 0 3rem;
    }
  
  }
}