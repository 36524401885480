.iconPlusBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(15%) saturate(1596%) hue-rotate(197deg) brightness(95%) contrast(88%);
  }

}
.iconPlusBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(25%) saturate(7492%) hue-rotate(230deg) brightness(104%) contrast(100%);
  }

}
.iconPlusBasic100 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1835%) hue-rotate(300deg) brightness(104%) contrast(107%);
  }

}
.iconPlusBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(94%) sepia(3%) saturate(941%) hue-rotate(198deg) brightness(93%) contrast(88%);
  }

}
.iconSettingsBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(16%) saturate(1681%) hue-rotate(197deg) brightness(99%) contrast(86%);
  }

}
.iconCloseBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(16%) sepia(25%) saturate(812%) hue-rotate(195deg) brightness(96%) contrast(92%);
  }

}
.iconCloseBasic100 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
  }

}
.iconMoreHorizontalBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(10%) sepia(43%) saturate(931%) hue-rotate(198deg) brightness(89%) contrast(80%);
  }

}
.iconMoreVerticalBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(16%) sepia(15%) saturate(1420%) hue-rotate(196deg) brightness(93%) contrast(91%);
  }

}
.iconArrowLeftBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(5%) saturate(6859%) hue-rotate(198deg) brightness(96%) contrast(81%);
  }

}
.iconArrowLeftBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(16%) sepia(35%) saturate(2639%) hue-rotate(215deg) brightness(112%) contrast(118%);
  }

}
.iconArrowRightBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(4%) saturate(6930%) hue-rotate(198deg) brightness(98%) contrast(88%);
  }

}
.iconArrowUpBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(16%) sepia(50%) saturate(418%) hue-rotate(195deg) brightness(89%) contrast(92%);
  }

}
.iconArrowDownBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(5%) saturate(5519%) hue-rotate(197deg) brightness(93%) contrast(85%);
  }

}
.iconChevronUpBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(26%) saturate(851%) hue-rotate(197deg) brightness(101%) contrast(91%);
  }

}
.iconChevronDownBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(28%) saturate(900%) hue-rotate(197deg) brightness(91%) contrast(87%);
  }

}
.iconChevronDownBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(95%) sepia(6%) saturate(983%) hue-rotate(190deg) brightness(93%) contrast(86%);
  }

}
.iconChevronLeftBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(15%) sepia(35%) saturate(602%) hue-rotate(195deg) brightness(96%) contrast(91%);
  }

}
.iconChevronRightBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(14%) saturate(1739%) hue-rotate(196deg) brightness(93%) contrast(87%);
  }

}
.iconAlertCircleBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(16%) sepia(29%) saturate(700%) hue-rotate(197deg) brightness(95%) contrast(94%);
  }

}
.iconAlertTriangleBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(12%) sepia(5%) saturate(6291%) hue-rotate(197deg) brightness(97%) contrast(84%);
  }

}
.iconQuestionMarkCircleBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(14%) sepia(11%) saturate(2374%) hue-rotate(197deg) brightness(96%) contrast(87%);
  }

}
.iconTrashBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(13%) sepia(21%) saturate(1286%) hue-rotate(196deg) brightness(92%) contrast(85%);
  }

}
.iconTrashRed200 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(15%) sepia(100%) saturate(6623%) hue-rotate(23deg) brightness(100%) contrast(101%);
  }

}
.iconPictureBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(16%) sepia(10%) saturate(2099%) hue-rotate(197deg) brightness(98%) contrast(93%);
  }

}
.iconFilmBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(13%) sepia(11%) saturate(2152%) hue-rotate(197deg) brightness(100%) contrast(87%);
  }

}
.iconRadioButtonOnBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(17%) sepia(21%) saturate(968%) hue-rotate(196deg) brightness(90%) contrast(93%);
  }

}
.iconRadioButtonOffBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(12%) sepia(5%) saturate(6475%) hue-rotate(198deg) brightness(96%) contrast(83%);
  }

}
.iconRadioButtonOnBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(96%) sepia(5%) saturate(1424%) hue-rotate(189deg) brightness(90%) contrast(93%);
  }

}
.iconRadioButtonOffBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(96%) sepia(57%) saturate(497%) hue-rotate(179deg) brightness(96%) contrast(82%);
  }

}
.iconRadioButtonOnBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(8%) sepia(62%) saturate(5183%) hue-rotate(237deg) brightness(103%) contrast(97%);
  }

}
.iconRadioButtonOffBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(11%) sepia(59%) saturate(5025%) hue-rotate(235deg) brightness(82%) contrast(96%);
  }

}
.iconCheckboxUncheckedBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(14%) sepia(31%) saturate(708%) hue-rotate(195deg) brightness(99%) contrast(90%);
  }

}
.iconCheckboxCheckedBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(41%) saturate(768%) hue-rotate(196deg) brightness(91%) contrast(83%);
  }

}
.iconCheckboxUncheckedBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(91%) sepia(14%) saturate(136%) hue-rotate(196deg) brightness(94%) contrast(88%);
  }

}
.iconCheckboxCheckedBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(83%) sepia(10%) saturate(188%) hue-rotate(197deg) brightness(102%) contrast(90%);
  }

}
.iconCheckboxUncheckedBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(10%) sepia(76%) saturate(3678%) hue-rotate(235deg) brightness(89%) contrast(99%);
  }

}
.iconCheckboxCheckedBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(10%) sepia(58%) saturate(4680%) hue-rotate(233deg) brightness(97%) contrast(96%);
  }

}
.iconHeartBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(13%) sepia(34%) saturate(721%) hue-rotate(196deg) brightness(98%) contrast(88%);
  }

}
.iconHeartFilledBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(7%) saturate(3884%) hue-rotate(197deg) brightness(95%) contrast(85%);
  }

}
.iconOptionsBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(14%) sepia(6%) saturate(3898%) hue-rotate(198deg) brightness(101%) contrast(90%);
  }

}
.iconCloseCircleFilledBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(15%) sepia(8%) saturate(2656%) hue-rotate(197deg) brightness(94%) contrast(90%);
  }

}
.iconCloseCircleFilledRed200 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(23%) sepia(49%) saturate(3478%) hue-rotate(360deg) brightness(101%) contrast(122%);
  }

}
.iconCheckmarkCircleFilledBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(12%) sepia(5%) saturate(6078%) hue-rotate(197deg) brightness(92%) contrast(83%);
  }

}
.iconCheckmarkCircleBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(16%) sepia(20%) saturate(985%) hue-rotate(196deg) brightness(95%) contrast(93%);
  }

}
.iconMenuBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(17%) sepia(38%) saturate(471%) hue-rotate(195deg) brightness(95%) contrast(96%);
  }

}
.iconExternalLinkBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    filter: brightness(0) saturate(100%) invert(15%) sepia(13%) saturate(1608%) hue-rotate(197deg) brightness(102%) contrast(91%);
  }

}
.iconShareBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(16%) sepia(17%) saturate(1082%) hue-rotate(196deg) brightness(97%) contrast(95%);
  }

}
.iconShare2Basic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(10%) saturate(2239%) hue-rotate(197deg) brightness(99%) contrast(90%);
  }

}
.iconShowBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(15%) sepia(10%) saturate(2316%) hue-rotate(197deg) brightness(94%) contrast(88%);
  }

}
.iconHideBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(19%) sepia(34%) saturate(519%) hue-rotate(195deg) brightness(87%) contrast(97%);
  }

}
.iconShowBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(10%) sepia(46%) saturate(4846%) hue-rotate(228deg) brightness(108%) contrast(96%);
  }

}
.iconHideBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(10%) sepia(72%) saturate(5129%) hue-rotate(238deg) brightness(75%) contrast(95%);
  }

}
.iconShowBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(99%) sepia(41%) saturate(1101%) hue-rotate(179deg) brightness(93%) contrast(87%);
  }

}
.iconHideBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(100%) sepia(24%) saturate(5793%) hue-rotate(180deg) brightness(106%) contrast(75%);
  }

}
.iconDashboardBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(20%) saturate(1281%) hue-rotate(196deg) brightness(97%) contrast(86%);
  }

}
.iconDashboardBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(93%) sepia(11%) saturate(244%) hue-rotate(198deg) brightness(92%) contrast(89%);
  }

}
.iconDashboardBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(18%) sepia(14%) saturate(7429%) hue-rotate(218deg) brightness(98%) contrast(115%);
  }

}
.iconQuotesBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(13%) sepia(8%) saturate(3469%) hue-rotate(197deg) brightness(95%) contrast(85%);
  }

}
.iconQuotesBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(94%) sepia(6%) saturate(606%) hue-rotate(198deg) brightness(93%) contrast(87%);
  }

}
.iconQuotesBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(12%) sepia(51%) saturate(4747%) hue-rotate(223deg) brightness(87%) contrast(87%);
  }

}
.iconApplicationsBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(14%) sepia(10%) saturate(2439%) hue-rotate(197deg) brightness(90%) contrast(87%);
  }

}
.iconApplicationsBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(96%) sepia(7%) saturate(1090%) hue-rotate(186deg) brightness(88%) contrast(98%);
  }

}
.iconApplicationsBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(58%) saturate(3947%) hue-rotate(229deg) brightness(95%) contrast(97%);
  }

}
.iconRateCardsBasic800 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(15%) sepia(10%) saturate(2505%) hue-rotate(197deg) brightness(90%) contrast(88%);
  }

}
.iconRateCardsBasic400 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(93%) sepia(10%) saturate(246%) hue-rotate(198deg) brightness(90%) contrast(93%);
  }

}
.iconRateCardsBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(11%) sepia(28%) saturate(7352%) hue-rotate(229deg) brightness(104%) contrast(99%);
  }

}
.iconEditBrand500 {
  align-self: center;
  width: 1.5rem;
  flex: 0 0 1.5rem;
  &:nth-child(1) {
    margin-top: 0;
  }

  .content {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%) invert(6%) sepia(77%) saturate(5608%) hue-rotate(240deg) brightness(120%) contrast(94%);
  }

}