.mainMenuItemDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(240, 241, 247);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemDefault:hover {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(228, 229, 240);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemDefault:pressed {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(228, 229, 240);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemDefault:focused {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(228, 229, 240);
  border: 2px solid rgb(26, 35, 126);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemDefault:disabled {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(240, 241, 247);

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.75rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.mainMenuItemSelected {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 3rem;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(228, 229, 240);

  .selector {
    align-self: stretch;
    height: 3rem;
    flex: 0 0 0.25rem;
    background-color: rgb(26, 35, 126);
  }

  .container {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 0.75rem 1rem 0.5rem;
    > * {
      margin-left: 0.75rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

  .icon {
    align-self: center;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}