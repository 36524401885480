.rateCardTableItemList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .rateCardTableItem {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    > * {
      margin-left: 2.5rem;
    }
    align-self: flex-start;
    width: 48.5625rem;
    flex: 0 0 auto;
  }

}