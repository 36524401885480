.dashboardRateCardList {
  display: grid;
  grid-template-columns: repeat(4, 16.62rem);
  column-gap: 1rem;
  row-gap: 1rem;
  margin: 0;
  padding: 0;

  .dashboardRateCard {
    height: 8rem;
    width: 16rem;
    margin: 0;
  }

}

@media (min-width: 576px) {
  .dashboardRateCardList {
    display: grid;
    grid-template-columns: repeat(4, 16.62rem);
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 0;
    padding: 0;
  
    .dashboardRateCard {
      height: 8rem;
      width: 16rem;
      margin: 0;
    }
  
  }
}

@media (min-width: 1024px) {
  .dashboardRateCardList {
    display: grid;
    grid-template-columns: repeat(4, 16.62rem);
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 0;
    padding: 0;
  
    .dashboardRateCard {
      height: 8rem;
      width: 16rem;
      margin: 0;
    }
  
  }
}