.select {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(210, 211, 223);
  border-radius: 4px;
  
  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.select:selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(72, 79, 152);
  border-radius: 4px;

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.select:error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(216, 36, 0);
  border-radius: 4px;

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}
.select:disabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  > * {
    margin-left: 0.5rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  &:nth-child(1) {
    margin-top: 0;
  }
  background-color: rgb(248, 248, 248);
  border: 1px solid rgb(210, 211, 223);
  border-radius: 4px;

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .icon {
    align-self: flex-start;
    height: 1.5rem;
    flex: 0 0 1.5rem;
    &:nth-child(1) {
      margin-left: 0;
    }
  }

}