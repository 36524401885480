.detailsSectionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(240, 241, 247);
  border-radius: 4px;

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .detailItemList {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}
.detailsSectionWithEditButton {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(240, 241, 247);
  border-radius: 4px;

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .text {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: center;
    flex: 1 1 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    > * {
      margin-left: 0.5rem;
    }
    align-self: center;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  }

  .detailItemList {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .detailsSectionDefault {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    > * {
      margin-top: 1rem;
    }
    align-self: flex-start;
    // width: 30rem;
    flex: 0 0 8rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(240, 241, 247);
    border-radius: 4px;

    .topContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-left: 0;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
    .text {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: flex-start;
      flex: 1 1 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  
    .detailItemList {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}

@media (min-width: 576px) {
  .detailsSectionWithEditButton {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    > * {
      margin-top: 1rem;
    }
    align-self: flex-start;
    // width: 30rem;
    flex: 0 0 8rem;
    &:nth-child(1) {
      margin-top: 0;
    }
    background-color: rgb(240, 241, 247);
    border-radius: 4px;
  
    .topContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      > * {
        margin-left: 0.5rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .text {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: center;
      flex: 1 1 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
    .detailItemList {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      > * {
        margin-left: 0.5rem;
      }
      align-self: center;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-left: 0;
      }
    }
  
  }
}