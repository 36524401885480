.instantQuoteLayout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .header {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

  .body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .bodyContent {
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 1 1 auto;
  }

  .simplePage {
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 0.5rem 2rem 0.5rem;
    > * {
      margin-top: 1rem;
    }
    align-self: stretch;
    flex: 1 1 auto;
  }

}

@media (min-width: 576px) {
  .instantQuoteLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
  
    .header {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .simplePage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0rem 2rem 0rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
  }
}

@media (min-width: 768px) {
  .instantQuoteLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
  
    .header {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .simplePage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 4rem 0rem 4rem 0rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
  }
}

@media (min-width: 1024px) {
  .instantQuoteLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
  
    .header {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .simplePage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 4rem 0rem 4rem 0rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
  }
}

@media (min-width: 1440px) {
  .instantQuoteLayout {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
  
    .header {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
    }
  
    .body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .bodyContent {
      overflow: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      flex: 1 1 auto;
    }
  
    .simplePage {
      overflow: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 4rem 0rem 4rem 0rem;
      > * {
        margin-top: 1.5rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
    }
  
  }
}