.textBrand500CenterLargeData {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 3rem;
    font-weight: normal;
    line-height: 4rem;
    text-align: center;
    color: rgb(26, 35, 126);
  }

}
.textBrand500LeftLargeCardData {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(26, 35, 126);
  }

}
.textBasic800CenterLargeCardData {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeHeading1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 2rem;
    font-weight: normal;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeHeading1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 2rem;
    font-weight: normal;
    line-height: 2.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800CenterLargeHeading1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 2rem;
    font-weight: normal;
    line-height: 2.5rem;
    text-align: center;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeHeading2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 2rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800CenterLargeHeading2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 2rem;
    text-align: center;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeHeading3 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeHeading4 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeSubtitle1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeSubtitle2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftLargeParagraph1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800CenterLargeParagraph1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(43, 45, 69);
  }

}
.textBasic100LeftLargeParagraph1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(255, 255, 255);
  }

}
.textBasic400LeftLargeParagraph1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(210, 211, 223);
  }

}
.textBasic500LeftLargeParagraph1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(168, 170, 191);
  }

}
.textRed200LeftLargeParagraph1 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textBasic800LeftMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textRed200LeftMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(216, 36, 0);
  }

}
.textBasic600LeftMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(128, 125, 144);
  }

}
.textBasic600CenterMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(128, 125, 144);
  }

}
.textBasic400LeftMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(210, 211, 223);
  }

}
.textBrand500LeftMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
    color: rgb(26, 35, 126);
  }

}
.textBrand500CenterMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
    color: rgb(26, 35, 126);
  }

}
.textBasic600RightMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: right;
    color: rgb(128, 125, 144);
  }

}
.textBasic400RightMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: right;
    color: rgb(210, 211, 223);
  }

}
.textBrand500RightMediumParagraph2 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: right;
    color: rgb(26, 35, 126);
  }

}
.textBasic800LeftMediumParagraph3 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }

}
.textBasic800LeftMediumParagraph4 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(43, 45, 69);
  }
}
@media (min-width: 768px) {
  .textBasic800LeftMediumParagraph4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}
.textBrand500LeftMediumParagraph3 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: left;
    color: rgb(26, 35, 126);
  }

}
.textBasic800CenterMediumParagraph3 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    color: rgb(43, 45, 69);
  }

}
.textBasic800RightMediumParagraph3 {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: right;
    color: rgb(43, 45, 69);
  }

}
.textBasic800CenterSmallButtonGiant {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1rem;
    text-transform: uppercase;
    text-align: center;
    color: rgb(43, 45, 69);
  }

}
.textBrand500CenterSmallButtonGiant {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1rem;
    text-transform: uppercase;
    text-align: center;
    color: rgb(26, 35, 126);
  }

}
.textRed200CenterSmallButtonGiant {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1rem;
    text-transform: uppercase;
    text-align: center;
    color: rgb(216, 36, 0);
  }

}
.textBasic100CenterSmallButtonGiant {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 767px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }

  .value {
    align-self: flex-start;
    flex: 1 1 auto;
    font-family: 'Lato', 'sans-serif';
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1rem;
    text-transform: uppercase;
    text-align: center;
    color: rgb(255, 255, 255);
  }

}

@media (min-width: 768px) {
  .textBrand500CenterLargeData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 3rem;
      font-weight: normal;
      line-height: 4rem;
      text-align: center;
      color: rgb(26, 35, 126);
    }
  
  }
}

@media (min-width: 768px) {
  .textBrand500LeftLargeCardData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1.25rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(26, 35, 126);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800CenterLargeCardData {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1.25rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeHeading1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 2rem;
      font-weight: normal;
      line-height: 2.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeHeading1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 2rem;
      font-weight: normal;
      line-height: 2.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800CenterLargeHeading1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 2rem;
      font-weight: normal;
      line-height: 2.5rem;
      text-align: center;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeHeading2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1.25rem;
      font-weight: normal;
      line-height: 2rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800CenterLargeHeading2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1.25rem;
      font-weight: normal;
      line-height: 2rem;
      text-align: center;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeHeading3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeHeading4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeSubtitle1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeSubtitle2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.75rem;
      font-weight: normal;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftLargeParagraph1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800CenterLargeParagraph1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic100LeftLargeParagraph1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(255, 255, 255);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic400LeftLargeParagraph1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(210, 211, 223);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic500LeftLargeParagraph1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(210, 211, 223);
    }
  
  }
}

@media (min-width: 768px) {
  .textRed200LeftLargeParagraph1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(216, 36, 0);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textRed200LeftMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: left;
      color: rgb(216, 36, 0);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic600LeftMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: left;
      color: rgb(128, 125, 144);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic600CenterMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: center;
      color: rgb(128, 125, 144);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic600RightMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: right;
      color: rgb(128, 125, 144);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic400LeftMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: left;
      color: rgb(210, 211, 223);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic400RightMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: right;
      color: rgb(210, 211, 223);
    }
  
  }
}

@media (min-width: 768px) {
  .textBrand500LeftMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: left;
      color: rgb(26, 35, 126);
    }
  
  }
}

@media (min-width: 768px) {
  .textBrand500CenterMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: center;
      color: rgb(26, 35, 126);
    }
  
  }
}

@media (min-width: 768px) {
  .textBrand500RightMediumParagraph2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem;
      text-align: right;
      color: rgb(26, 35, 126);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800LeftMediumParagraph3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBrand500LeftMediumParagraph3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.5rem;
      text-align: left;
      color: rgb(26, 35, 126);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800CenterMediumParagraph3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.5rem;
      text-align: center;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800RightMediumParagraph3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.5rem;
      text-align: right;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic800CenterSmallButtonGiant {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1rem;
      text-transform: uppercase;
      text-align: center;
      color: rgb(43, 45, 69);
    }
  
  }
}

@media (min-width: 768px) {
  .textBrand500CenterSmallButtonGiant {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1rem;
      text-transform: uppercase;
      text-align: center;
      color: rgb(26, 35, 126);
    }
  
  }
}

@media (min-width: 768px) {
  .textRed200CenterSmallButtonGiant {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1rem;
      text-transform: uppercase;
      text-align: center;
      color: rgb(216, 36, 0);
    }
  
  }
}

@media (min-width: 768px) {
  .textBasic100CenterSmallButtonGiant {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    &:nth-child(1) {
      margin-top: 0;
    }
  
    .value {
      align-self: flex-start;
      flex: 1 1 auto;
      font-family: 'Lato', 'sans-serif';
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 1rem;
      text-transform: uppercase;
      text-align: center;
      color: rgb(255, 255, 255);
    }
  
  }
}