.logoLarge {
  overflow: hidden;

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(43%) sepia(71%) saturate(7150%) hue-rotate(341deg) brightness(100%) contrast(94%);
  }

}
.logoSmall {
  overflow: hidden;

  .content {
    width: 100%;
    height: 100%;
    // filter: brightness(0) saturate(100%) invert(33%) sepia(98%) saturate(2631%) hue-rotate(335deg) brightness(92%) contrast(106%);
  }

}