.rateCardList {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1rem;
  }

  .rateCard {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.25rem 1.25rem 1.25rem 1.25rem;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .rateCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
      margin-left: 1rem;
    }
  
    .rateCard {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.25rem 1.25rem 1.25rem 1.25rem;
      > * {
        margin-top: 0.25rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: calc(50% - 0.5rem);
      @media (max-width: 767px) {
        &:nth-child(-n+2) {
          margin-top: 0;
        }
        &:nth-child(2n+1) {
          margin-left: 0;
        }
      }
    }
  
  }
}

@media (min-width: 768px) {
  .rateCardList {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1rem;
      margin-left: 1rem;
    }
  
    .rateCard {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.25rem 1.25rem 1.25rem 1.25rem;
      > * {
        margin-top: 0.25rem;
      }
      align-self: flex-start;
      flex: 1 1 auto;
      max-width: calc(50% - 0.5rem);
      &:nth-child(-n+2) {
        margin-top: 0;
      }
      &:nth-child(2n+1) {
        margin-left: 0;
      }
    }
  
  }
}