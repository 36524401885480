.leaseDetailsSection {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 1rem 1rem 1rem;
  > * {
    margin-top: 1rem;
  }
  align-self: stretch;
  flex: 0 0 auto;
  @media (max-width: 575px) {
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  background-color: rgb(240, 241, 247);
  border-radius: 4px;

  .detailItemList {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 0.25rem;
    }
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

}

@media (min-width: 576px) {
  .leaseDetailsSection {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2.5rem 2.5rem 2.5rem 2.5rem;
    > * {
      margin-top: 1rem;
    }
    align-self: flex-start;
    // width: 30rem;
    flex: 0 0 8rem;
    @media (max-width: 575px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    background-color: rgb(240, 241, 247);
    border-radius: 4px;
  
    .detailItemList {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 0 0 auto;
      @media (max-width: 575px) {
        &:nth-child(1) {
          margin-top: 0;
        }
      }
    }
  
  }
}