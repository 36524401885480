.clickableRateCardList {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 0rem;
    margin-left: 0rem;
  }

  @media (min-width: 576px) {
    .clickableRateCard {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.25rem 1.25rem 1.25rem 1.25rem;
      > * {
        margin-top: 0.25rem;
      }
      align-self: stretch;
      flex: 1 1 auto;
      max-width: calc(50% - 0.5rem);
      &:nth-child(-n+2) {
        margin-top: 0;
      }
      &:nth-child(2n+1) {
        margin-left: 0;
      }
    }
  }

}