.topBlock {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  > * {
    margin-top: 1.5rem;
  }
  flex: 0 0 auto;

  .blockHeader {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .tabs {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
    @media (max-width: 767px) {
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    display: inline;
  }

}

@media (min-width: 768px) {
  .topBlock {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
      margin-top: 1.5rem;
    }
    flex: 0 0 auto;
  
    .blockHeader {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  
    .tabs {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      align-self: stretch;
      flex: 0 0 auto;
      &:nth-child(1) {
        margin-top: 0;
      }
      display: inline;
    }
  
  }
}