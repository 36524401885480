.contextualMenu {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 0rem 0.5rem 0rem;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.10000000149011612);

  .contextualMenuItemList {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    flex: 0 0 auto;
  }

}